.main {
  padding: 3rem 0rem;
}

.anchor {
  padding-top: 6em;
  margin-top: 3em;
}

.logo {
  text-align: center;
}

.logo > img {
  width: 80%;
  height: 80%;
}

.water-hr {
  background-image: url(./images/blue-water.jpg);
  background-size: cover;
  width: 100%;
  height: 10px;
}

.accent {
  font-size: larger;
  color: rgba(24, 22, 126, 0.781);
  font-weight: 600;
}

.copyright {
  text-align: center;
  padding: 30px;
}

.card-img-right {
  height: 100%;
  border-radius: 0 3px 3px 0;
}

.flex-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.featurette {
  padding: 2em !important;
}

@media (min-width: 900px) {
  .logo {
    margin-top: 4em;
    /* max-height: 200px; */
  }
  .logo > img {
    width: 70%;
    height: 70%;
  }
}

@media (min-width: 1200px) {
  .logo {
    margin-top: 4em;
    /* max-height: 200px; */
  }
  .logo > img {
    width: 50%;
    height: 50%;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 0rem !important;
  }
}
